import React from 'react';
import { Link } from 'gatsby';
import Login from '../components/Login';

const login = ({ location }) => {
    const { state: routeState } = location;
    const redirect = !routeState
        ? '/app'
        : routeState.redirect === 'app'
        ? '/app'
        : `/app/${routeState.redirect}`;

    return (
        <>
            <h1>Login</h1>
            {/* <p>Please use your credentials to login</p> */}
            <Login redirect={redirect} />
            <p>
                No Account? <Link to='/signup'>Sign up</Link> instead
            </p>
        </>
    );
};

export default login;
